.container {
  margin-top: var(--spacing-top); /* Dev default as part of margin refactor */
  margin-bottom: var(--spacing-bottom);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  padding-inline: var(--spacing-sides);
}

.inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.intro {
  margin-bottom: 32px;
  max-width: 300px;

  @media (width >= 650px) {
    margin-bottom: 64px;
    max-width: 790px;
  }

  @media (width >= 1000px) {
    margin-bottom: 96px;
  }
}

.intro-title {
  margin-bottom: 24px;
  color: var(--page-theme-contrast-color, var(--color-text-heading));
  font-weight: var(--font-weight-heading);
  font-family: var(--font-family-heading);

  @media (width >= 650px) {
    margin-bottom: 32px;
    font-size: var(--font-size-48px);
  }
}

.intro-text {
  margin-inline: auto;
  max-width: 600px;
  color: var(--page-theme-contrast-color, var(--color-text-subtle));
  line-height: 1.5;

  @media (width >= 650px) {
    font-size: var(--font-size-24px);
  }
}

/* TILES */

.tiles {
  display: grid;
  grid-gap: 0;
  position: relative;
  grid-template-rows: repeat(9, 1fr);
  grid-template-columns: repeat(6, 1fr);
  align-items: start;
  justify-items: center;
  width: 100%;

  @media (width >= 650px) {
    grid-gap: 24px;
    grid-template-columns: repeat(8, 1fr);
  }

  @media (width >= 1000px) {
    grid-gap: 24px;
    grid-template-rows: repeat(5, 1fr);
    grid-template-columns: repeat(12, 1fr);
    height: 65%;
  }
}

.tile {
  display: flex;
  position: relative;
  align-items: flex-end;
  opacity: 0;
  z-index: 1;
  box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.16);
  border-radius: var(--radius-large);
  width: 100%;
  overflow: hidden;
  text-decoration: none;
}

.tiles--with-animation .tile {
  animation: loadSingleTile 0.5s forwards;
}

.tile--horizontal::before {
  padding-top: 75%;
  content: "";
}

.tile--vertical::before {
  padding-top: 125%;
  content: "";
}

.tile-inner {
  padding: 12px;

  @media (width >= 650px) {
    padding: 20px;
  }

  @media (width >= 1000px) {
    padding: 24px;
  }
}

.tile-inner::before {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.12) 40%,
    rgba(0, 0, 0, 0.6)
  );
  width: 101%;
  height: 101%;
  content: "";
}

.tiles:nth-of-type(even) .tile:nth-child(1) {
  grid-row: 1 / span 2;
  grid-column: 2 / span 3;
  z-index: 5;

  @media (width >= 650px) {
    grid-column: 2 / span 4;
  }

  @media (width >= 1000px) {
    top: calc(24px * -1);
    grid-row: 3 / span 2;
    grid-column: 9 / span 4;
    z-index: 5;
    animation-delay: 0.2s;
  }
}

.tiles:nth-of-type(even) .tile:nth-child(2) {
  left: calc(12px * -1);
  grid-row: 2 / span 3;
  grid-column: 3 / span 4;
  align-self: center;
  z-index: 4;
  animation-delay: 0.1s;

  @media (width >= 650px) {
    grid-column: 4 / span 5;
  }

  @media (width >= 1000px) {
    left: calc(24px * -1);
    grid-row: 1 / span 3;
    grid-column: 7 / span 5;
    align-self: center;
    z-index: 4;
    animation-delay: 0.1s;
  }
}

.tiles:nth-of-type(even) .tile:nth-child(3) {
  grid-row: 3 / span 4;
  grid-column: 1 / span 3;
  align-self: center;
  z-index: 3;
  animation-delay: 0.2s;

  @media (width >= 650px) {
    grid-column: 1 / span 4;
  }

  @media (width >= 1000px) {
    left: 24px;
    grid-row: 1 / span 4;
    grid-column: 3 / span 4;
    align-self: start;
    z-index: 3;
  }
}

.tiles:nth-of-type(even) .tile:nth-child(4) {
  grid-row: 5 / span 4;
  grid-column: 2 / span 4;
  align-self: center;
  z-index: 1;
  animation-delay: 0.3s;

  @media (width >= 650px) {
    grid-column: 3 / span 5;
  }

  @media (width >= 1000px) {
    left: calc(24px * -1);
    grid-row: 3 / span 3;
    grid-column: 5 / span 5;
    align-self: end;
    z-index: 1;
    animation-delay: 0.4s;
  }
}

.tiles:nth-of-type(even) .tile:nth-child(5) {
  grid-row: 7 / span 3;
  grid-column: 4 / span 3;
  align-self: center;
  z-index: 2;
  animation-delay: 0.4s;

  @media (width >= 650px) {
    grid-column: 5 / span 4;
  }

  @media (width >= 1000px) {
    left: 24px;
    grid-row: 3 / span 2;
    grid-column: 1 / span 4;
    align-self: start;
    z-index: 2;
    animation-delay: 0.3s;
  }
}

@media (width >= 1000px) {
  html[dir="rtl"] .tiles:nth-of-type(even) .tile:nth-child(2) {
    grid-column: 6 / span 5;
  }

  html[dir="rtl"] .tiles:nth-of-type(even) .tile:nth-child(5) {
    grid-column: 2 / span 4;
  }
}

.tile-image {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--color-surface-solid-light-lighter);
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@keyframes loadSingleTile {
  from {
    transform: translateY(20%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
